import React, { useState } from "react";
import "./style.css";
import { FaRegTimesCircle } from "react-icons/fa";

const PopupRound = ({ children, title, status }) => {
    const [isShow, setIsShow] = useState(status);

    return (
        <aside className={`popup-round ${status !== isShow ? "show" : "hide"}`}>
            <header>
                {title && <h2>{title}</h2>}
                <FaRegTimesCircle className="btn-close" onClick={() => setIsShow(!isShow)} />
            </header>
            <main>{children}</main>
        </aside>
    );
};

export default PopupRound;
